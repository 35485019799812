<template>
  <div class="cpt-MMC_Gimbal_FN4" :style="containerStyle">
    <div class="hd" v-interact>
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">飞鹰抓捕网</div>
      </div>
      <div class="close" @click="$emit('close'), $emit('showCenter', false)">关闭</div>
    </div>
    <div class="bd">
      <div class="rocker-wrap">
        <div class="title-box">
          <!-- <div class="title">操作</div> -->
        </div>
        <div class="rocker" ref="rocker">
          <div></div>
        </div>
      </div>
      <div class="form-wrap">
        <div class="form-item">
          <div class="label-box">保险：</div>
          <div class="input-box">
            <el-switch v-model="safety_switch_state" size="mini"></el-switch>
          </div>
        </div>
        <div class="form-wrap_fs">
          <div class="form-item launch" v-for="(item,i) in 4" :key="i">
            <img
              class="cp FE8button"
              ref="FE8button"
              src="~@/assets/images/mount/launch.png"
              alt
              @click="handle_laser_shine_ctrl(i)"
            />
            <div class="label-box">发射{{i + 1}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    containerStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      timer: null,
      radio: 1,
      safety_switch_state: true,
      laser_shine_status: false,
    };
  },
  methods: {
    handle_laser_shine_ctrl(i) {
      if (this.safety_switch_state) {
        this.$message.warning("请先关闭保险");
        return (this.laser_shine_status = false);
      } else {
        let buff = null;
        if (i == 0) {
          buff = [165,32,3,1,101];
        } else if (i == 1) {
          buff = [165,3,3,1,214];
        } else if (i == 2) {
          buff = [165,4,3,1,53];
        } else if (i == 3) {
          buff = [165,5,3,1,115];
        }
        this.commit_directive(buff);
        this.$message.success("操作成功");
        let dom = this.$refs.FE8button[i];
        dom.style.transform = "scale(1.1)";
        dom.style.opacity = "1";
        dom.style.boxShadow = "0px 0px 10px rgb(32, 56, 248) ";
        setTimeout(() => {
          dom.style.transform = "scale(1)";
          dom.style.opacity = "0.7";
          dom.style.boxShadow = "0px 0px 0px rgb(32, 56, 248)";
        }, 100);
      }
    },
    commit_directive(buffer) {
      console.log(buffer);
      this.$emit("directive", buffer);
    },
  },
  mounted() {
    this.$emit("showCenter", true);
  },
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_FN4 {
  position: absolute;
  // bottom: 0;
  right: 0;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  width: 470px;
  height: 290px;

  box-sizing: border-box;

  .hd {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;

    .left {
      display: flex;
      align-items: center;

      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .bd {
    padding: 10px 20px;
    display: flex;
    // justify-content: space-between;
    overflow-y: auto;
    height: 230px;
    overflow-x: hidden;

    .form-wrap {
      margin-left: 90px;
      padding-top: 10px;
      // min-width: 300px;
      // flex-shrink: 0;
      .form-item {
        display: flex;
        align-items: center;
        .label-box {
          color: #cbd0eb;
          // width: 75px;
        }

        .input-box {
          display: flex;
          align-items: center;

          .icon-box {
            [class^="el-icon-"] {
              font-size: 30px;
              color: #dce9ff;
            }
          }
        }
      }

      .launch {
        // margin-top: 35px;
        // margin-left: 22px;
        display: flex;
        flex-direction: column;

        .FE8button {
          opacity: 0.7;
          border-radius: 50%;
        }
      }
    }

    .rocker-wrap {
      // flex: 1;
      .title-box {
        .title {
          color: #cbd0eb;
        }
      }

      .rocker {
        margin-top: 30px;
        width: 153px;
        height: 166px;
        position: relative;
        background: center url("~@/assets/images/observe/MMC_Gimbal_FN4.png")
          no-repeat;
        background-size: 100% 100%;

        .shangUp {
          position: absolute;
          left: 30%;
          top: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .xaiUp {
          position: absolute;
          left: 30%;
          bottom: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .zuoUp {
          position: absolute;
          left: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .youUp {
          position: absolute;
          right: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
      }
    }
    .form-wrap_fs {
      display: flex;
      flex-wrap: wrap;
      column-gap: 15px;
      width: 210px;
      margin-top: 10px;
    }
  }
}

::v-deep {
  .el-select {
    width: 165px;
    margin-right: 5px;

    .el-input {
      .el-input__inner {
        background: #000000;
        border: 1px solid #08c2d1;
        color: #dce9ff;
      }
    }
  }

  .el-input {
    width: 165px;
    margin-right: 5px;

    .el-input__inner {
      background: #000000;
      border: 1px solid #08c2d1;
      color: #dce9ff;
    }
  }

  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #08c2d1;
            background: #08c2d1;
          }
        }
      }

      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }

  .el-button {
    background: #2aefed;
    color: #000;
    border: none;
  }
}
</style>